import { Component, Input, OnInit, inject } from '@angular/core';
import { ChartResultTypeInterface } from '../../model/monitor.model';
import { ChartApexOptions } from '../apex-chart/apex-chart.component';
import { ChartType } from '../../sem-chart/configuration/sem-chart-config';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export enum DateRangeEnum {
  OneMonth = '1m',
  SixMonths = '6m',
  OneYear = '1y',
  YearToDate = '1yd',
  All = 'all',
}

@Component({
  selector: 'app-timeseries-chart-select',
  templateUrl: './timeseries-chart-select.component.html',
  styleUrls: ['./timeseries-chart-select.component.scss'],
})
export class TimeseriesChartSelectComponent implements OnInit {
  protected selectedResultType!: ChartResultTypeInterface;
  protected chartOptions: Partial<ChartApexOptions> = {};
  protected selected: ChartResultTypeInterface | undefined;
  protected activeOptionButton = DateRangeEnum.All;
  protected range!: FormGroup;
  protected readonly ChartType = ChartType;
  protected rangeEnum = DateRangeEnum;
  private fb = inject(FormBuilder);
  private translate = inject(TranslateService);
  private onDestroy$ = new Subject<void>();

  constructor() {
    this.range = this.fb.group({
      start: null,
      end: null,
    });
  }

  private _resultTypes!: ChartResultTypeInterface[];

  get resultTypes(): ChartResultTypeInterface[] {
    return this._resultTypes;
  }

  @Input() set resultTypes(data: ChartResultTypeInterface[]) {
    this._resultTypes = data;
    if (!this.selected) {
      this.selected = data[0];
    }
    this.updateCharts(false);
  }

  ngOnInit(): void {
    this.range.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        filter((rangeValues) => rangeValues.start && rangeValues.end),
      )
      .subscribe(() => {
        this.updateCharts(true);
      });
  }

  getSortedRangeEnum(): { key: string; value: DateRangeEnum }[] {
    const ranges = Object.entries(this.rangeEnum).map(([key, value]) => ({ key, value }));

    return ranges.sort((a, b) => {
      if (a.value === DateRangeEnum.All) return 1;
      if (b.value === DateRangeEnum.All) return -1;
      return 0;
    });
  }

  updateCharts(reloadChart: boolean = true) {
    if (!this.resultTypes || !this.selected) {
      console.error('No resultTypes or selected type available.');
      return;
    }

    const lang = this.translate.currentLang || 'en';
    const dateFormatter = new Intl.DateTimeFormat(lang, { day: '2-digit', month: 'short', year: 'numeric' });

    this.selectedResultType = this.resultTypes.find((resultType) => resultType.value === this.selected?.value)!;

    if (!this.selectedResultType || !this.selectedResultType.dataset) {
      console.error('Selected result type or dataset is invalid.');
      return;
    }

    const rawData = this.selectedResultType.dataset;
    let seriesData;

    if (this.selectedResultType.value === 'avg_pos') {
      seriesData = [
        {
          name: this.selectedResultType.yAxisName,
          data: rawData.map((item) => ({
            x: new Date(item.date).getTime(),
            y: item.avg,
          })),
        },
      ];
    } else if (this.selectedResultType.value === 'top') {
      const seriesMap: { [key: string]: { name: string; data: { x: number; y: number }[] } } = {};

      rawData.forEach((item) => {
        const seriesKey = item.key;
        if (!seriesMap[seriesKey]) {
          seriesMap[seriesKey] = {
            name: seriesKey,
            data: [],
          };
        }
        seriesMap[seriesKey].data.push({
          x: new Date(item.date).getTime(),
          y: item.total,
        });
      });

      seriesData = Object.values(seriesMap);
    }

    let strokeSettings: { width: number; dashArray: number; curve: string }[] | undefined;

    if (seriesData) {
      strokeSettings = seriesData.map((series: any, index: number) => ({
        width: 2,
        dashArray: index >= 2 ? 3 : 0,
        curve: 'smooth',
      }));
    }

    const minDate = this.range.value.start ? new Date(this.range.value.start).getTime() : undefined;
    const maxDate = this.range.value.end ? new Date(this.range.value.end).getTime() : undefined;

    const maxYValue = Math.max(...rawData.map((item) => (this.selectedResultType.value === 'avg_pos' ? item.avg : item.total)));
    const yAxisMax = maxYValue * 1.1;

    this.chartOptions = {
      series: seriesData,
      chart: {
        type: ChartType.line,
        height: 350,
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: true,
        },
        animations: {
          enabled: reloadChart,
        },
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: 'Date',
        },
        min: minDate,
        max: maxDate,
        labels: {
          formatter: (value: string) => {
            const timestamp = parseInt(value, 10);
            return dateFormatter.format(new Date(timestamp));
          },
        },
      },
      stroke: strokeSettings
        ? {
            width: strokeSettings.map((setting) => setting.width),
            curve: 'smooth',
            dashArray: strokeSettings.map((setting) => setting.dashArray),
          }
        : undefined,
      yaxis: {
        title: {
          text: this.selectedResultType.yAxisName,
        },
        max: yAxisMax,
      },
      tooltip: {
        shared: true,
        intersect: false,
        followCursor: false,
        x: {
          formatter: (val: number) => dateFormatter.format(new Date(val)),
        },
        y: {
          formatter: (val: number) => {
            return val.toFixed(2);
          },
        },
      },
      markers: {
        size: 0,
      },
      fill: {
        type: 'solid',
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
      },
    };
  }

  public updateOptions(range: DateRangeEnum): void {
    const now = new Date();
    let minDate: number | undefined;
    let maxDate = now.getTime();

    switch (range) {
      case DateRangeEnum.OneMonth:
        minDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()).getTime();
        break;
      case DateRangeEnum.SixMonths:
        minDate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate()).getTime();
        break;
      case DateRangeEnum.OneYear:
        minDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()).getTime();
        break;
      case DateRangeEnum.YearToDate:
        minDate = new Date(now.getFullYear(), 0, 1).getTime();
        break;
      case DateRangeEnum.All:
        minDate = undefined;
        break;
      default:
        minDate = undefined;
        break;
    }

    this.range.patchValue({
      start: minDate ? new Date(minDate) : null,
      end: new Date(maxDate),
    });

    this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        ...this.chartOptions.xaxis,
        min: minDate,
        max: maxDate,
      },
    };

    this.activeOptionButton = range;
  }
}
