<div *ngIf="name; else noName" class="user-tag">
  <div class="avatar" [ngClass]="{ 'tw-opacity-50': user.holiday }">
    <div class="circle" [ngStyle]="{ 'background-color': circleColor }">
      <div *ngIf="initials" class="initials">
        {{ initials }}
      </div>
    </div>
  </div>
  <input #input *ngIf="edit" [value]="name" />
  <div *ngIf="!edit" class="name" [class.is-bold]="isUserBold">{{ name }}</div>

  @if (user.holiday) {
    <div>
      @if (holidayInfoType === 'icon') {
        @let tooltip =
          'tasks.user.on_holidays' | translate: { from: user.holiday.from | date: 'dd-MM-yyyy', to: user.holiday.to | date: 'dd-MM-yyyy' };

        <mat-icon
          class="!tw-size-4 tw-text-base tw-leading-4 tw-align-middle tw-ml-1 material-symbols-outlined"
          matTooltipClass="tooltip-max-width-100"
          [matTooltip]="tooltip"
          >calendar_month</mat-icon
        >
      }

      @if (holidayInfoType === 'untilLabel') {
        @let untilLabel = user.holiday.to | userTagHolidayUntilLabel;

        @if (untilLabel) {
          <span class="tw-pl-1">
            ({{ 'tasks.user.unavailable_until' | translate }}:
            {{
              untilLabel === 'date'
                ? (user.holiday.to | date: 'dd-MM-yyyy')
                : ('tasks.user.unavailable_until_type.' + untilLabel | translate)
            }})
          </span>
        }
      }
    </div>
  }
</div>

<ng-template #noName></ng-template>
