import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChartLineChooserForm } from '../chart-line-chooser/chart-line-chooser-form';
import { ChartMapper } from './chart-mapper';

export enum ChartType {
  msline = 'msline',
  multiaxisline = 'multiaxisline',
  msarea = 'msarea',
  msbar2d = 'msbar2d',
  mscombidy2d = 'mscombidy2d',
  timeseries = 'stackedcolumn2d',

  line = 'line',
  area = 'area',
  donut = 'donut',
  bar = 'bar',
  column = 'column',
}

export const SEM_CHART_CONFIG = new InjectionToken<SemChartConfig>('SemChartConfig');
export const SEM_CHART_MAPPER_DIRECTOR = new InjectionToken<SemChartDirector>('SEM_CHART_MAPPER_DIRECTOR');
export const SEM_CHART_Y_LINE_FORM = new InjectionToken<ChartLineChooserForm>('SEM_CHART_Y_LINE_FORM');
export const SEM_CHART_DATA_STREAM = new InjectionToken<BehaviorSubject<any>[]>('SEM_CHART_DATA_STREAM');

export interface SemChartConfig {
  width?: string;
  height?: string;
  seriesName?: string;
  yLine?: string[];
  title?: string;
  xAxisName?: string;
  theme: 'apex';
  uniqueId?: string;
}

export const Y_LINES_COLORS = ['#3498DB', '#66BB6A', '#FF8080'];

export interface SemChartDirector<TData = any, TOptions = any> {
  construct: () => ChartMapper<any>;
  generateChartOptions?: (dataSource: TData) => Partial<TOptions>;
}

export const defaultSemChartConfig: SemChartConfig = {
  height: '350',
  width: '100%',
  theme: 'apex',
};
