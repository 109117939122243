export enum TaskStatusEnum {
  canceled = 'canceled',
  finished = 'finished',
  ignored = 'ignored',
  inProgress = 'in_progress',
  info = 'info',
  new = 'new',
}

export enum TaskPriorityEnum {
  veryLow = 'very_low',
  low = 'low',
  medium = 'medium',
  high = 'high',
  veryHigh = 'very_high',
}

export enum TaskTriggerJobStatusEnum {
  WAITING,
  PENDING,
  DONE,
  ERROR,
}

export enum TaskTriggerFrequencyEnum {
  hourly = 'hourly',
  every6hours = 'four_times_a_day',
  every12hours = 'twice_a_day',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'once_a_month',
}

export enum AnswerTypeEnum {
  INTERNAL_NOTE = 'internal_note',
  SEND_EMAIL = 'send_email',
}

export enum CommentSourceEnum {
  ALL = 'all',
  MAIL = 'mail',
  COMMENT = 'comment',
}

export enum MessageMarkerType {
  MARK_AS_SEEN = 'mark_comment_as_seen',
  LIKE = 'like_comment',
  DISLIKE = 'dislike_comment',
}
