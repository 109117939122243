import { Type } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ActionTypesEnum } from '../../../action/ActionTypesEnum';
import { filterTypesGroups } from '../../../enums';
import { FilterTypesEnum } from '../../../filters/FilterTypesEnum';
import { ItemModel } from '../../../item.model';
import { CustomButtonPerItemInterface } from '../../../models/CustomButton.model';
import { ObjectMapping, SimpleMapping } from '../../../models/Mapping.model';
import { ToolInterface } from '../../../models/TableConfigurationInterface.model';
import { AgregationInterface } from '../../../models/agregation.model';
import { ColumnDirectivesEnum, ColumnTypesEnum, ColumnValueTypesEnum } from '../../ColumnTypesEnum';

type ValueMappingType = SimpleMapping | ObjectMapping;

export interface ColumnComponentModel {
  component: Type<any>;
  inputsMapping: any;
  valueMapping?: ValueMappingType;
}

export interface ColumnsStorageSetModel {
  name: {
    name: string;
    saveAsTab?: boolean;
  };

  columnNames: string[];
  active: boolean;
}

export interface ColumnsSetModel {
  name: string;
}

export abstract class ColumnModel {
  ableToFilter?: boolean;
  actionsAvailableTypes: ActionTypesEnum[] = [ActionTypesEnum.override, ActionTypesEnum.setEmpty];
  aggregate?: boolean;
  aggregateBySelect?: AgregationInterface;
  alias!: string;
  autocomplete$?: Subject<any[]>;
  canAiGenerated?: boolean;
  canBeSorted?: boolean;
  canCopy?: boolean;
  component?: ColumnComponentModel;
  customButton?: CustomButtonPerItemInterface[];
  dialog?: { config: MatDialogConfig; component: any };
  directive?: ColumnDirectivesEnum;
  displayFullLength?: boolean; // displays full length no matter how long
  editable?: ToolInterface;
  filterAvailableTypes: FilterTypesEnum[] = filterTypesGroups.default;
  group?: string; // name of group
  grouping?: boolean; // is able to group (grouping logic still in progress)
  onlyFromAutocomplete? = false;
  param!: string;
  required!: boolean;
  styleClass?: string;
  toTranslate?: boolean = false;
  tooltip?: string;
  type!: ColumnTypesEnum;
  valueMapping?: ValueMappingType;
  valueType?: ColumnValueTypesEnum;
  disabledIn?: {
    duplicatePopup?: boolean;
    editPopup?: boolean;
    addPopup?: boolean;
    usingAsFilterInMassOperations?: boolean;
  };

  baseColumnName?: string; // Klucz do filtrowania i sortowania jeśli ma być inny niż ten kolumny, np. assignee_ids dla assignees
  defaultFilterType?: FilterTypesEnum = FilterTypesEnum.equal; // Domyślny typ filtrowania (uzywany np. w quick filters)
  responseMapping?: any; // Definiowanie zwrotki jeśli ma być inna niż value danej kolumny

  defaultValue?: any;
  description?: string;

  _isNavigationColumn?: boolean;
  _key?: string;

  map(value: any): any {
    return this.valueMapping ? this.valueMapping.map(value) : value;
  }

  isEditableInItem(item: ItemModel) {
    return this.editable && (this.editable.activeAlways || item[this.editable.dependsOnParam as keyof typeof item]);
  }

  setComponent(component: ColumnComponentModel | null = null) {
    if (component) {
      this.component = component;
      this.valueMapping && (this.component.valueMapping = this.valueMapping);
    }
  }
}
