import { APP_BASE_HREF, NgOptimizedImage, PlatformLocation, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localePl from '@angular/common/locales/pl';
import { APP_INITIALIZER, ENVIRONMENT_INITIALIZER, NgModule, Type, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import 'src/app/shared/helpers/array.helper';
import 'src/app/shared/helpers/string.helper';
import { environment } from 'src/environments/environment';
import { AnonymousPaymentPlanComponent } from './anonymous-payment-plan/anonymous-payment-plan.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ComparatorResolver } from './dashboard/comparator/comparator.resolver';
import { PaymentsModule } from './dashboard/payments/payments.module';
import { SettingsModule } from './dashboard/profile/settings/settings.module';
import { ProjectResolver } from './dashboard/project/project.resolver';
// import { ShowFeedbackPopupService } from './dashboard/services/show-feedback-popup.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MAT_TABS_CONFIG, MatTabsConfig } from '@angular/material/tabs';
import { TitleStrategy } from '@angular/router';
import { FixComponent } from './fix/fix.component';
import { AuthGuard } from './guards/auth.guard.service';
import { GuestGuard } from './guards/quest.guard.service';
import { SuperuserGuard } from './guards/superuser.guard.service';
import { WhiteLabelGuard } from './guards/whitelabel.guard.service';
import { AuthIntercecptorService } from './interceptors/auth-interceptor.service';
import { ClientVersionIntercecptorService } from './interceptors/client-version-interceptor.service';
import { ErrorIntercecptorService } from './interceptors/error-interceptor.service';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { NotificationModule } from './notification/notification.module';
import { MatErrorComponent } from './shared/components/mat-error/mat-error.component';
import { MaterialModule } from './shared/material-module/material.module';
import { RefreshAppModalComponent } from './shared/modals/refresh-app-modal/refresh-app-modal.component';
import { HelperService } from './shared/service/helper.service';
import { PluginService } from './shared/service/plugin.service';
import { TranslationService } from './shared/service/translation.service';
import { SharedModule } from './shared/shared.module';
import { ShopifyComponent } from './shopify/shopify.component';
import { TranslatedTitleStrategy } from './translated-title-strategy';
import { NgApexchartsModule } from 'ng-apexcharts';

export function initApp(translationService: TranslationService) {
  return () => translationService.initAppTranslation();
}

export function pluginHandler(pluginService: PluginService) {
  return () => (environment.pluginMode ? pluginService.prepareAppBridge() : of());
}

registerLocaleData(localeEn);
registerLocaleData(localePl);

export const CUSTOM_MAT_FORM_FIELD_DEFAULT_OPTIONS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  floatLabel: 'always',
};

const CUSTOM_MAT_TABS_CONFIG: MatTabsConfig = {
  stretchTabs: false,
};

@NgModule({
  declarations: [
    AnonymousPaymentPlanComponent,
    AppComponent,
    FixComponent,
    MaintenanceComponent,
    MatErrorComponent,
    RefreshAppModalComponent,
    ShopifyComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    MaterialModule,
    NotificationModule,
    PaymentsModule,
    SharedModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
    }),
    SettingsModule,
    AuthModule,
    NgOptimizedImage,
    NgApexchartsModule,
  ],
  providers: [
    AuthGuard,
    GuestGuard,
    WhiteLabelGuard,
    SuperuserGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthIntercecptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercecptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientVersionIntercecptorService,
      multi: true,
    },
    ProjectResolver,
    ComparatorResolver,
    HelperService,
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: pluginHandler,
      deps: [PluginService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [TranslationService],
      multi: true,
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initStripe,
    //   deps: [PaymentsService],
    //   multi: true,
    // },
    { provide: 'googleTagManagerId', useValue: 'GTM-KR75CPG' },
    { provide: TitleStrategy, useClass: TranslatedTitleStrategy },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: CUSTOM_MAT_FORM_FIELD_DEFAULT_OPTIONS,
    },
    {
      provide: MAT_TABS_CONFIG,
      useValue: CUSTOM_MAT_TABS_CONFIG,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => {
        // @TODOL @TMP: wyłączenie popupa do momentu wprowadzenia poprawek: https://app.asana.com/0/1206609340772094/1206905953914297
        // const globalServicesTypes: Array<Type<unknown>> = [ShowFeedbackPopupService];
        const globalServicesTypes: Array<Type<unknown>> = [];
        globalServicesTypes.forEach((token) => inject(token));
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
