import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ExternalServiceCampaignEnum } from 'src/app/dashboard/project/campaign/enums/ExternalServiceCampaignEnum';
import { GoogleAccountInterface } from 'src/app/dashboard/project/campaign/models/googleAccount.interface';
import { default as ApiUrls, default as apiUrlsConfig } from '../../configs/api-urls.config';
import {
  AdwordsCustomer,
  ConnectionUrlInterface,
  FacebookAccount,
  FacebookBusiness,
  FacebookPage,
  FacebookPost,
  MerchantCenterAccount,
  ServicesSlugsEnum,
} from '../../dashboard/project/connections/connections.model';
import { TriggerConnectionInterface } from '../../dashboard/tasks/triggers/triggers.model';
import { BrandEnum } from '../enums/brand.enum';
import { ConnectionsBrandEnum } from '../enums/connections-service.enum';
import { ConnectionModel, ConnectionParamsInterface, SelectedConnectionsInterface, ServiceModel } from '../model/connections.model';
import { PaginationInterface, PaginationResourceInterface, ResponseInterface, ResponseV2Interface } from '../model/response.model';
import { ModalsService } from './modals.service';

const betaServices = [
  ServicesSlugsEnum.analytics4,
  ServicesSlugsEnum.gmc,
  ServicesSlugsEnum.gsc,
  ServicesSlugsEnum.shopify,
  ServicesSlugsEnum.facebookBusiness,
];

const AVAILABLE_AND_SORTED_SERVICES = [
  ServicesSlugsEnum.analytics4,
  ServicesSlugsEnum.gsc,
  ServicesSlugsEnum.adwords,
  ServicesSlugsEnum.bing,
  ServicesSlugsEnum.gmc,
  ServicesSlugsEnum.shopify,
  ServicesSlugsEnum.facebook,
  ServicesSlugsEnum.facebookBusiness,
  ServicesSlugsEnum.analytics,
];

enum ConnectionServiceEnum {
  adwords = 'ads',
  analytics = 'analytics',
  analytics4 = 'analytics4',
  gsc = 'google-search-console',
  facebook = 'facebook',
  gmc = 'google-merchant-center',
  shopify = 'shopify',
}

export interface ConnectionParmsInterface {
  page?: number;
  param?: string;
  perPage?: number;
  services?: ServicesSlugsEnum[];
}

@Injectable({
  providedIn: 'root',
})
export class ConnectionsService {
  connectionSelectForm: FormControl<ConnectionModel[]> = new FormControl([], { nonNullable: true });
  openConnectionSelect$: Subject<void> = new Subject<void>();

  private areAvailableConnections: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private http = inject(HttpClient);
  private readonly modalsService = inject(ModalsService);

  get areAvailableConnections$(): Observable<boolean> {
    return this.areAvailableConnections.asObservable();
  }

  getSelectValue(): ConnectionModel[] {
    return this.connectionSelectForm.getRawValue();
  }

  getServices(): Observable<ServiceModel[]> {
    return this.http.get<ResponseV2Interface<ServiceModel[]>>(ApiUrls.connectionsServices).pipe(
      map((res) => res.data),
      map((data) =>
        data
          .sortAndFilterByValues(AVAILABLE_AND_SORTED_SERVICES, 'slug')
          .map((service) => ({ ...service, ...{ _isBeta: betaServices.includes(service.slug) } })),
      ),
    );
  }

  getAuthURL(
    type: string,
    services: number[],
    redirectToProject: boolean = false,
    project_id?: number,
  ): Observable<ConnectionUrlInterface> {
    const redirect = redirectToProject ? 'PROJECTS' : 'CONNECTIONS';
    let servicesStringGET = `${services[0]}`;
    services.forEach((string, index) => {
      if (index > 0) {
        servicesStringGET = `${servicesStringGET},${string}`;
      }
    });

    const params = new HttpParams()
      .set('redirect', redirect)
      .set('type', type)
      .set('project_id', `${project_id}`)
      .set('services', servicesStringGET);

    return this.http.get<ResponseInterface<ConnectionUrlInterface>>(ApiUrls.connectionsUrl, { params }).pipe(
      map((res) => res.data.item),

      map((item) => {
        const existingEnumValue = Object.entries(ConnectionsBrandEnum).find(([key]) => key === type);
        existingEnumValue && (item!.brand = existingEnumValue[1] as unknown as BrandEnum);
        return item!;
      }),
    );
  }

  getRefreshURL(
    type: string,
    services: number[],
    connection_id: number,
    redirectToProject: boolean = false,
    project_id?: number,
  ): Observable<ConnectionUrlInterface> {
    const redirect = redirectToProject ? 'PROJECTS' : 'CONNECTIONS';
    let servicesStringGET = `${services[0]}`;
    services.forEach((string, index) => {
      if (index > 0) {
        servicesStringGET = `${servicesStringGET},${string}`;
      }
    });

    const params = new HttpParams()
      .set('redirect', redirect)
      .set('type', type)
      .set('project_id', `${project_id}`)
      .set('connection_id', `${connection_id}`)
      .set('services', servicesStringGET);

    return this.http.get<ResponseInterface<ConnectionUrlInterface>>(ApiUrls.connectionsUrl, { params }).pipe(
      map((res) => res.data.item),
      map((item) => {
        const existingEnumValue = Object.entries(ConnectionsBrandEnum).find(([key]) => key === type);
        existingEnumValue && (item!.brand = existingEnumValue[1] as unknown as BrandEnum);
        return item!;
      }),
    );
  }

  getUserConnections(page: number = 1, per_page?: number): Observable<PaginationInterface<ConnectionModel>> {
    let params = new HttpParams().set('page', page.toString());
    params = per_page ? params.set('per_page', per_page.toString()) : params;
    return this.http.get<ResponseV2Interface<ConnectionModel[]>>(ApiUrls.connections, {
      params,
    });
  }

  deleteUserConnection(connectionId: number): Observable<any> {
    return this.http
      .delete<ResponseInterface<any>>(ApiUrls.connection.prepareUrl({ connection: connectionId }))
      .pipe(map((res) => res.data));
  }

  getProjectConnections(projectId: number, queryParams: ConnectionParmsInterface = {}): Observable<PaginationInterface<ConnectionModel>> {
    const { page, param, perPage, services } = queryParams;
    let params = new HttpParams().set('page', String(page || 1)).set('per_page', String(perPage || 20));

    param && (params = params.set('param', param));
    services && services.forEach((service) => (params = params.append('services[]', service)));

    return this.http
      .get<PaginationResourceInterface<ConnectionModel>>(ApiUrls.projectConnections.prepareUrl({ project: projectId }), {
        params,
      })
      .pipe(
        map(({ data, meta, links }) => {
          this.areAvailableConnections.next(data.length > 0);
          return { data, ...meta, ...links };
        }),
      );
  }

  getConnectionsForService(serviceId: number): Observable<PaginationInterface<ConnectionModel>> {
    return this.http
      .get<ResponseV2Interface<ConnectionModel[]>>(ApiUrls.connectionsServiceConnections.prepareUrl({ service: serviceId }))
      .pipe(
        map((res: ResponseV2Interface<ConnectionModel[]>) => ({
          data: res.data.sort(
            (a: ConnectionModel, b: ConnectionModel) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
          ),
        })),
      );
  }

  getAdwordsCustomer(connectionId: number, search?: string): Observable<PaginationInterface<AdwordsCustomer>> {
    const params = search ? new HttpParams().set('search', search) : new HttpParams();

    return this.http.get<ResponseV2Interface<AdwordsCustomer[]>>(
      ApiUrls.connectionsServicesAdCustomers.prepareUrl({ connection: connectionId }),
      {
        params,
      },
    );
  }

  getAnalyticsAccounts(connectionId: number): Observable<PaginationInterface<{ id: string; name: string }>> {
    return this.http.get<ResponseV2Interface<{ id: string; name: string }[]>>(
      ApiUrls.connectionsServicesAnalyticsAccounts.prepareUrl({ connection: connectionId }),
    );
  }

  getMerchantCenterAccounts(projectId: number, connectionId: number, accountId: string | null = null): Observable<MerchantCenterAccount[]> {
    let params = new HttpParams();
    accountId && (params = params.set('account_id', accountId.toString()));

    return this.http
      .get<ResponseV2Interface<MerchantCenterAccount[]>>(
        ApiUrls.projectGoogleMerchantCenterAccountsByConnection.prepareUrl({
          project: projectId,
          connection: connectionId,
        }),
        { params },
      )
      .pipe(map((res) => res.data));
  }

  getAnalytic4Accounts(connectionId: number): Observable<PaginationInterface<{ id: string; name: string }>> {
    return this.http.get<PaginationResourceInterface<{ id: string; name: string }>>(
      ApiUrls.connectionsServicesAnalytics4Accounts.prepareUrl({ connection: connectionId }),
    );
  }

  getGscSites(connectionId: number): Observable<PaginationInterface<{ url: string }>> {
    return this.http.get<ResponseV2Interface<{ url: string }[]>>(
      ApiUrls.connectionsServicesGscSites.prepareUrl({ connection: connectionId }),
    );
  }

  getAnalyticsProperties(
    connectionId: number,
    accountId: number,
  ): Observable<
    PaginationInterface<{
      id: string;
      name: string;
    }>
  > {
    const params = new HttpParams().set('accountId', `${accountId}`);
    return this.http.get<ResponseV2Interface<{ id: string; name: string }[]>>(
      ApiUrls.connectionsServicesAnalyticsProperties.prepareUrl({ connection: connectionId }),
      { params },
    );
  }

  getAnalyticsProfiles(
    connectionId: number,
    accountId: string,
    propertyId: string,
  ): Observable<PaginationInterface<{ id: string; name: string }>> {
    const params = new HttpParams().set('accountId', accountId).set('propertyId', propertyId);
    return this.http.get<PaginationResourceInterface<{ id: string; name: string }>>(
      ApiUrls.connectionsServicesAnalyticsProfiles.prepareUrl({ connection: connectionId }),
      { params },
    );
  }

  getAnalytics4Properties(
    connectionId: number,
    accountId: string,
  ): Observable<
    PaginationInterface<{
      id: string;
      name: string;
    }>
  > {
    const params = new HttpParams().set('accountId', accountId);
    return this.http.get<PaginationResourceInterface<{ id: string; name: string }>>(
      ApiUrls.connectionsServicesAnalytics4Properties.prepareUrl({ connection: connectionId }),
      { params },
    );
  }

  attachAnalyticsToProject(projectId: number, connection_id: number, account_id: string, property_id: string, profile_id: string) {
    return this.http.post<
      ResponseInterface<{
        id: string;
        name: string;
      }>
    >(ApiUrls.projectConnections.prepareUrl({ project: projectId }), {
      connection_id,
      service: ConnectionServiceEnum.analytics,
      account_id,
      property_id,
      profile_id,
    });
  }

  attachGscToProject(projectId: number, connection_id: number, site: string) {
    return this.http.post<
      ResponseInterface<{
        id: string;
        name: string;
      }>
    >(ApiUrls.projectConnections.prepareUrl({ project: projectId }), {
      connection_id,
      service: ConnectionServiceEnum.gsc,
      site,
    });
  }

  attachAnalytics4ToProject(projectId: number, connection_id: number, account_id: string, property_id: string, property_name: string) {
    return this.http.post<
      ResponseInterface<{
        id: string;
        name: string;
      }>
    >(ApiUrls.projectConnections.prepareUrl({ project: projectId }), {
      connection_id,
      service: ConnectionServiceEnum.analytics4,
      account_id,
      property_id,
      property_name,
    });
  }

  attachAdwordsToProject(projectId: number, customerId: string, customer_name: string, customerInner: string, connection_id: number) {
    return this.http.post<
      ResponseInterface<{
        id: string;
        name: string;
      }>
    >(ApiUrls.projectConnections.prepareUrl({ project: projectId }), {
      connection_id,
      service: ConnectionServiceEnum.adwords,
      customer_id: customerInner,
      login_customer_id: customerId,
      customer_name,
    });
  }

  attachFacebookToProject(
    projectId: number,
    connectionId: number,
    businessId: string,
    businessName: string,
    adAccountId: string,
    adAccountName: string,
    pageId: string,
    pageName: string,
  ) {
    return this.http.post<
      ResponseInterface<{
        id: string;
        name: string;
      }>
    >(ApiUrls.projectConnections.prepareUrl({ project: projectId }), {
      connection_id: connectionId,
      service: ConnectionServiceEnum.facebook,
      business_id: businessId,
      business_name: businessName,
      ad_account_id: adAccountId,
      ad_account_name: adAccountName,
      page_id: pageId,
      page_name: pageName,
    });
  }

  attachShopifyToProject(projectId: number, connection_id: number) {
    return this.http.post<
      ResponseInterface<{
        id: string;
        name: string;
      }>
    >(ApiUrls.projectConnections.prepareUrl({ project: projectId }), {
      connection_id,
      service: ConnectionServiceEnum.shopify,
    });
  }

  attachGoogleMerchantCenterProject(projectId: number, connection_id: number, accountId: string, accountName: string) {
    return this.http.post<ResponseInterface<void>>(ApiUrls.projectConnections.prepareUrl({ project: projectId }), {
      connection_id,
      service: ConnectionServiceEnum.gmc,
      account_id: accountId,
      account_name: accountName,
    });
  }

  dettachConnectionFromProject(connectionId: number, projectId: number) {
    return this.http.delete<ResponseInterface<{ id: string; name: string }>>(
      ApiUrls.projectComparatorsConnection.prepareUrl({ project: projectId, connectionPivot: connectionId }),
    );
  }

  getConnectionsForCampaign(projectId: number, serviceType: ExternalServiceCampaignEnum): Observable<GoogleAccountInterface[]> {
    const params = new HttpParams().set('type', serviceType);

    return this.http.get<any>(ApiUrls.projectCampaignsConnections.prepareUrl({ project: projectId }), { params }).pipe(
      map((res) => res.data),
      map((data) => Object.keys(data).map((key) => ({ id: +key, name: data[key] }))),
    );
  }

  getAccountsForCampaign(projectId: number, connectionId: number): Observable<GoogleAccountInterface[]> {
    return this.http
      .get<any>(
        ApiUrls.projectCampaignsConnection.prepareUrl({
          project: projectId,
          connection: connectionId,
        }),
      )
      .pipe(
        map((res) => res.data),
        map((data) => Object.keys(data).map((key) => ({ id: +key, name: data[key] }))),
      );
  }

  shopifyConnect(apiKey: string, apiAccessToken: string, storeDomain: string): Observable<void> {
    return this.http.post<void>(ApiUrls.connectionsShopifyConnect, {
      api_key: apiKey,
      api_access_token: apiAccessToken,
      store_domain: storeDomain,
    });
  }

  getInputFeedConnectionsList(projectId: number): Observable<ConnectionModel[]> {
    return this.http
      .post<ResponseV2Interface<ConnectionModel[]>>(ApiUrls.projectFeedInputSearchConnections.prepareUrl({ project: projectId }), {})
      .pipe(map((res) => res.data));
  }

  // --- Facebook:

  getBusinessesForFacebook(projectId: number, connectionId: number): Observable<FacebookBusiness[]> {
    return this.http
      .get<
        ResponseV2Interface<FacebookBusiness[]>
      >(ApiUrls.businessesForFacebookConnection.prepareUrl({ project: projectId, connection: connectionId }))
      .pipe(map((res) => res.data));
  }

  getAdAccountsForFacebook(projectId: number, connectionId: number): Observable<FacebookAccount[]> {
    return this.http
      .get<
        ResponseV2Interface<FacebookAccount[]>
      >(ApiUrls.adAccountsForFacebookConnection.prepareUrl({ project: projectId, connection: connectionId }))
      .pipe(map((res) => res.data));
  }

  getPagesForFacebook(projectId: number, connectionId: number): Observable<FacebookPage[]> {
    return this.http
      .get<
        ResponseV2Interface<FacebookPage[]>
      >(ApiUrls.pagesForFacebookConnection.prepareUrl({ project: projectId, connection: connectionId }))
      .pipe(map((res) => res.data));
  }

  getPostForFacebook(projectId: number, connectionId: number): Observable<FacebookPost[]> {
    return this.http
      .get<
        ResponseV2Interface<FacebookPost[]>
      >(ApiUrls.postsForFacebookConnection.prepareUrl({ project: projectId, connection: connectionId }))
      .pipe(map((res) => res.data));
  }

  getFacebookBusinnessConnections(projectId: number) {
    return this.http.get<any>(ApiUrls.projectFacebookBusinnessConnections.prepareUrl({ project: projectId })).pipe(map((res) => res.data));
  }

  getFacebookAdvantageObjectives(projectId: number): Observable<string[]> {
    // ["OUTCOME_SALES", "CONVERSIONS"]
    return this.http
      .get<ResponseV2Interface<string[]>>(ApiUrls.facebookAdvantageObjectives.prepareUrl({ project: projectId }))
      .pipe(map((res) => res.data));
  }

  getFacebookAdvantagePixels(projectId: number, connectionId: number): Observable<{ id: string; name: string }[]> {
    return this.http
      .get<
        ResponseV2Interface<{ id: string; name: string }[]>
      >(ApiUrls.facebookAdvantagePixelsByConnection.prepareUrl({ project: projectId, connection: connectionId }))
      .pipe(map((res) => res.data));
  }

  getFacebookTargetingCountries(projectId: number): Observable<{ code: string; name: string }[]> {
    return this.http
      .get<
        ResponseV2Interface<
          {
            code: string;
            name: string;
          }[]
        >
      >(ApiUrls.facebookTargetingCountries.prepareUrl({ project: projectId }))
      .pipe(map((res) => res.data));
  }

  openConnectionSelect() {
    this.openConnectionSelect$.next();
  }

  saveSelectedConnections(data: ConnectionParamsInterface, project_id: number): Observable<void> {
    return this.http.post<void>(apiUrlsConfig.profileSettingsSetStorage, {
      data,
      name: 'connectionIds',
      project_id,
    });
  }

  getSelectdConnections(projectId: number): Observable<SelectedConnectionsInterface> {
    const params: HttpParams = new HttpParams().set('project_id', projectId).set('name', 'connectionIds');
    return this.http
      .get<{ data: SelectedConnectionsInterface }>(apiUrlsConfig.profileSettingsGetStorage, { params })
      .pipe(map((res: { data: SelectedConnectionsInterface }) => res.data));
  }

  setOneConnection(connections: ConnectionModel[] | TriggerConnectionInterface[] | GoogleAccountInterface[]): number | null {
    if (!connections || connections.length === 0) {
      return null;
    }
    const selectedConnectionsIds: number[] = this.getSelectValue().map((connection: ConnectionModel) => connection.id);
    const filteredConnectionsIds: number[] = connections
      .filter((connection) => selectedConnectionsIds.includes(connection.id))
      .map((connection) => connection.id);
    return filteredConnectionsIds[0] || connections[0].id;
  }

  showConnectionsInfoModal(): void {
    this.modalsService
      .confirm('confirmations.connection_info')
      ?.afterClosed()
      .pipe(filter((res) => !!res))
      .subscribe(() => this.openConnectionSelect());
  }
}
